<template>
  <div v-loading.lock.fullscreen="isLoading"
    :element-loading-text="$t('authentication.logging-in')">
    <!-- Landing page post cognito auth -->
  </div>
</template>

<script>
import { showModalAlert } from '@/components/authentication/AuthHelpers.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { LOGIN_COGNITO } from '@/Globals.js'
import integrations from '@/third-party-integrations/integrations.js'

export default {
  inject: ['disabledFeatures', 'isFeatureEnabled'],
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters('Auth', ['isDistributorAccount', 'hasCompany', 'hasFeatureFlag', 'requiresMfaSetup', 'homePage']),
  },

  created() {
    // aws lambdas return error messages in the error_description query param
    if (this.$route.query.error_description?.includes('MappedEmailIsEmpty')) {
      this.$router.push('/login', () => { /* empty */ })
      showModalAlert(this.$t('authentication.sso-email-mapping-error'), this.$t('authentication.failed'), 'error', true)

      return
    }

    // no code == invalid redirect, so return to login and alert of failure
    if (!this.$route.query.code) {
      this.$router.push('/login', () => { /* empty */ })
      showModalAlert(this.$t('authentication.login-cognito-unexpected-failure'), this.$t('authentication.failed'), 'error')

      return
    }

    integrations.awsAmplify?.addHubListener({
      signInCallback: this.signInCallback,
      signInFailureCallback: this.signInFailureCallback,
    })
  },

  methods: {
    ...mapActions('Auth', ['login']),
    ...mapMutations('Auth', ['logout']),
    ...mapMutations(['setG2NewLogin']),
    ...mapActions('Services', ['getMyServices']),

    signInCallback(tokens) {
      const { access_token, id_token } = tokens
      const payload = { network: LOGIN_COGNITO, access_token, id_token }

      this.login({ payload })
        .then(this.signInSuccess)
        .catch(this.signInError)
    },

    signInSuccess() {
      integrations
        .awsAmplify
        .refreshSession()
        .then(session => {
          localStorage.setItem('_accessToken', session.accessToken.jwtToken)

          if (!this.hasCompany) {
            return this.redirectToCompanyTradingName()
          }

          this.completeLogin()
        })
        .catch(error => {
          this.$router.push('/login', () => { /* empty */ })
          showModalAlert(this.$t('authentication.login-failed-token'), this.$t('authentication.failed'), 'error')

          integrations.sentry.captureException(error)
        })
    },

    signInError(error) {
      // 401 = MFA required, so redirect to MFA screen
      if (error?.status === 401 && error.data?.data === '2FA') {
        this.$router.push('/mfa')
        return
      }

      // 404 = FF is enabled on the BE and user doesn't exist, so need to capture trading name + signup
      if (error?.status === 404) {
        this.redirectToCompanyTradingName()
        return
      }

      this.$router.push('/login', () => { /* empty */ })
      showModalAlert(error?.data.message, this.$t('authentication.failed'), 'error')
    },

    signInFailureCallback(error) {
      this.$router.push('/login', () => { /* empty */ })
      showModalAlert(this.$t('authentication.failed-login'), this.$t('authentication.failed'), 'error')
      integrations.sentry.captureException(error)
    },

    redirectToCompanyTradingName() {
      this.$router.push({ name: 'CompanyTradingName' }, () => { /* empty */ })
    },

    async completeLogin() {
      // This handles if the force password reset flag is set, as all endpoints will return 401 bad session token
      this.setG2NewLogin(true)

      try {
        if (!this.requiresMfaSetup) await this.getMyServices()
        this.$router.push(this.homePage)
      } catch {
        this.logout()
        showModalAlert(this.$t('authentication.failed-login'), this.$t('authentication.failed'), 'error')
      }
    },
  },
}

</script>
